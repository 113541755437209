<template>
    <v-row>
        <!--        <purposesCloudResume />

        <usersRecognitionsResume />

        <purposesCloudHistory />

        <exportRecognitionsReport />

        <exportReportFull />-->
    </v-row>
</template>

<script>
import purposesCloudResume from '@/components/admin/dashboard/purposesCloudResume'
import purposesCloudHistory from '@/components/admin/dashboard/purposesCloudHistory'
import usersRecognitionsResume from "@/components/admin/dashboard/usersRecognitionsResume";
import exportReportFull from '@/components/admin/dashboard/exportReportFull'
import exportRecognitionsReport from "@/components/admin/dashboard/exportRecognitionsReport";

export default {
    name: "AdminDashBoard",
    // components: { purposesCloudResume, exportReportFull, purposesCloudHistory, usersRecognitionsResume, exportRecognitionsReport },
    data: () => ({

    })
};
</script>
